<template lang="pug">
div(class='Cases')
	div(class='Info')
		h2
			span(class='Accent') {{ casesState.preHeader }}
			|
			| {{ casesState.header }}
		div(class='Description', v-html='casesState.text')
		SliderCases(v-if='lessThan("desktop")', :cases='casesState.photo')
		UiButton(theme='filled', size='xl', rounded, @click='getCasesModal') {{ casesState.button }}
	div(v-if='greaterThan("tablet")', class='Items')
		template(v-for='(item, index) in casesState.photo', :key='item.id')
			div(v-if='index < 3', class='Item')
				div(class='Name') {{ item.title }}
				UiImage(:image='item', width='400px', lazy)
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
const { casesState, fetchCases } = useCases();
const { modalState, modalShow } = useModal();

/**
 * Get cases modal
 */
const getCasesModal = () => {
	if (!casesState.value.form) return;

	modalState.value = {
		header: casesState.value.form.header,
		description: casesState.value.form.description,
		source: casesState.value.form.source,
		subject: casesState.value.form.subject,
		form: casesState.value.form.fields,
		target: casesState.value.form.target,
	};

	modalShow();
};

/**
 * Define fetch
 */
await fetchCases();
</script>

<style lang="less" scoped>
.Cases {
	.box(grid; 100%; auto; none; 3rem; auto; 1fr; center; center);
	& > .Info {
		.grid(1 span);
		.box(grid; 100%; auto; none; 0.5rem; auto; 1fr; center; start);
		& > .Description {
			.box(block; auto; auto; none);
			.text(@ColorBase; 1.6em 0.875rem @regular; left);
		}
	}
	& > .Items {
		.grid(1 span);
		.box(grid; 100%; auto; none; 1.25rem; repeat(2, 1fr); repeat(2, 1fr); stretch; stretch);
		& > .Item {
			.relative(1; hidden);
			.grid(1 span);

			& > .Name {
				padding: 0.5rem 1rem;
				.box(block; auto; auto; @ColorWhite);
				.text(@ColorBase; 1.25em 1rem @medium; left; none none);
				.border-radius(@BorderRadiusFull);
			}

			& > img {
				object-fit: cover;
				.box(block; 100%; 100%);
				.border-radius(@BorderRadiusSmall);
			}

			&:nth-child(1) {
				.grid-aspect(2.65; 2);
				.grid(1 2; 1 2);
				& > .Name {
					.absolute(1; none; 0.5rem 0.5rem);
				}
			}
			&:nth-child(2) {
				.grid-aspect(2.65; 2);
				.grid(2 3; 1 2);
				& > .Name {
					.absolute(1; none; none 0.5rem 0.5rem);
				}
			}
			&:nth-child(3) {
				.grid(1 3; 2 3);
				& > .Name {
					.absolute(1; none; 0.5rem 0.5rem);
				}
			}
		}
	}

	@media all and (min-width: @mobile) and (max-width: (@tablet - 1px)) {
		& > .Info {
			& > .Description {
				.text(@ColorBase; 1.6em 1rem @regular; left);
			}
		}
	}

	@media all and (min-width: @tablet) and (max-width: (@laptop - 1px)) {
		& > .Info {
			& > .Description {
				.text(@ColorBase; 1.6em 1.125rem @regular; left);
			}
		}
	}

	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; none; 3rem; auto; 1fr 1.2fr; center; center);

		& > .Info {
			& > .Description {
				max-width: 32rem;
				.box(block; auto; auto; none);
				.text(@ColorBase; 1.6em 1.25rem @regular; left);
			}
		}
	}
}
</style>
