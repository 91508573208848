import { casesService } from '~/services/cases';
import { Cases } from '~/models/cases';

export const useCasesState = () => ({
	casesState: useState<Cases.Model>('casesState', () => Cases.modelState),
	casesLoaded: useState<boolean>('casesLoaded', () => false),
	casesPending: useState<boolean>('casesPending', () => true),
});

export const useCases = () => {
	const { casesState, casesLoaded, casesPending } = useCasesState();

	/**
	 * Fetch cases
	 */
	const fetchCases = async () => {
		if (casesLoaded.value) return;

		casesPending.value = true;

		casesState.value = await casesService.fetch();

		casesLoaded.value = true;
		casesPending.value = false;
	};

	/**
	 * Refresh cases
	 */
	const refreshCases = async () => {
		casesLoaded.value = false;

		await fetchCases();
	};

	return {
		casesState,
		casesPending,

		fetchCases,
		refreshCases,
	};
};
