import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';

export namespace Cases {
	export type Model = Common.Model & {
		preHeader: string;
		header: string;
		text: string;
		photo: Image.Model[];
		button: string;
		form: Form.Model;
	};

	export const modelState: Model = {
		...Common.modelState,
		preHeader: '',
		header: '',
		text: '',
		photo: [],
		button: '',
		form: Form.modelState,
	};
}
